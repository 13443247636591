import { Model, Attribute, BelongsTo, HasMany } from './decorators';
import { BaseApiModel } from './base';
import { TopicType, WebhookType } from '@parashift/shared/types';
import { Webhook } from './webhook.model';

export interface TopicPlainModel {
  id: string;
  created_at: string;
  name: string;
  slug: string;
  updated_at: string;

  webhook: Webhook['plainModel'] | Partial<Webhook['plainModel']>;
  webhooks: (Webhook['plainModel'] | Partial<Webhook['plainModel']>)[];
}

@Model({ type: TopicType })
export class Topic extends BaseApiModel<TopicPlainModel> {

  @Attribute()
  created_at: string;

  @Attribute()
  name: string;

  @Attribute()
  slug: string;

  @Attribute()
  updated_at: string;

  // Includes / Relations

  @BelongsTo({ class: WebhookType, readonly: true })
  webhook: Webhook;

  @HasMany({ class: WebhookType, readonly: true })
  webhooks: (Webhook | Partial<Webhook>)[];
}
